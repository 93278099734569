import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeSpeaker: "speakers-0",
  activeSfxMusicEffect: "sfx-0",
  selectedOptEntry: "sfx",
};

const activeSpeakerSlice = createSlice({
  name: "activeSpeaker",
  initialState,
  reducers: {
    setActiveSpeaker: (state, action) => {
      state.activeSpeaker = action.payload;
    },
    setActiveSfxMusicEffect: (state, action) => {
      state.activeSfxMusicEffect = action.payload;
    },
    setSelectedOptEntry: (state, action) => {
      state.selectedOptEntry = action.payload;
    },
  },
});

export const {
  setActiveSpeaker,
  setSelectedOptEntry,
  setActiveSfxMusicEffect,
} = activeSpeakerSlice.actions;

export default activeSpeakerSlice.reducer;
