import React, { useRef, useState } from "react";
import Modal from "react-modal";
import "../../../../css/dark/modals.css";
import { useSelector } from "react-redux";
import { getDuration } from "../../../../utils/utils";
import "bootstrap/dist/css/bootstrap.css";
import SfxMusicLengthModal from "../../../modals/SfxMusicLengthModal";
import { FileUp } from "lucide-react";

const FileUploader = (props) => {
  const fileInputRef = useRef(null);

  // for modal
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [value, setValue] = useState(1.0);
  const [sfxFileImport, setSfxFileImport] = useState(false);
  const [duration, setDuration] = useState(0);
  const [file, setFile] = useState(null);
  const lastclicked = useSelector((state) => state.selectedRegion);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    // console.log(event.target.files);
    const selectedFile = event.target.files[0];
    // Reset the input value to allow re-selecting the same file
    fileInputRef.current.value = null;
    if (props.src === "kooliospace") {
      props.onFileSelect(selectedFile);
    } else {
      props.onFileImport(selectedFile);
    }
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
        accept=".wav,.mp3,.mp4,.aac,.ogg,.m4a"
      />

      <FileUp onClick={handleFileSelect} className="operations-icon" />
    </>
  );
};

export default FileUploader;
