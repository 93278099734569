import React from "react";
import "../../css/dark/MobileUnsupportedModal.css";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";

const MobileUnsupportedModal = () => {
  return (
    <div className="mobileUnsupprted-wrapper">
      <div className="container">
        <img
          src="/image/landing-page/koolio.ai.png"
          alt="koolio.ai"
          className="koolio-logo-image"
        />
        <h3 className="unsupported-screen-text">
          This screen size is not supported yet. <br /> Please switch to larger
          screen size <br /> devices to use koolio.ai
        </h3>
      </div>
    </div>
  );
};

export default MobileUnsupportedModal;
