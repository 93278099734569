import { _config, Koolioai } from "../cognitoAuth";
import axios from "axios";
import { exportHighQualityAudio, showStatText } from "../utils";
import { transistorSettings } from "./ApiIntegrationAuth";
import store from "../../redux/Store";
import {
  selectedFileFormat,
  selectedFileName,
} from "../../components/pages/workspace/modals/PublishIndividualUIModal";
export function publishAudioToTransistor(
  apiKey,
  showTitle,
  episodeTitle,
  fileToUpload,
  episodeSummary,
  options,
) {
  const fileName = selectedFileName + "." + selectedFileFormat;

  let showID = 0; // 26175
  transistorSettings.shows.forEach((show) => {
    if (show.title === showTitle) {
      showID = show.id;
    }
  });

  const publishInfo = {
    apiKey,
    fileName,
    titleOrPodcastId: showID,
    episodeTitle,
    apiProvider: "transistor",
    episodeSummary,
  };
  return exportHighQualityAudio(options, false, true, publishInfo).then((res) =>
    Promise.resolve(),
  );
}

export function getUserShowsFromTransistor() {
  const transistorGetUserShows = _config.api + "/api-transistor-get-shows";
  let userShows = [];
  let accessToken = transistorSettings.accessToken;
  if (transistorSettings.accessToken === "") {
    const apiKey = document.getElementById("api-key").value.trim();
    accessToken = apiKey;
  }
  const params = {
    apiKey: accessToken,
  };
  return Promise.all([Koolioai.authToken()]).then((tokens) => {
    return axios
      .get(transistorGetUserShows, {
        params,
        headers: {
          Authorization: `${tokens[0]}`,
        },
      })
      .then((res) => {
        const shows = res.data.data;
        userShows = shows.map((show) => {
          const showObj = {
            id: show.id,
            title: show.attributes.title,
          };
          return showObj;
        });
        transistorSettings.shows = userShows;
        return Promise.resolve(userShows);
      })
      .catch((e) => {
        // console.error(e)
        showStatText(
          "Sorry, couldn't find the shows for user. Please try again later.",
        );
      });
  });
}
