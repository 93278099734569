import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../../../../css/dark/transcriptPanel.css";
import { useDispatch } from "react-redux";
import store from "../../../../redux/Store";
import { multiSelectWordActions } from "../../../../redux/slice/MultiSelectWordList";
import { multiSelectActions } from "../../../../redux/slice/MultiselectSlice";
import ExportTranscript from "../../../../components/modals/exportTranscriptmodal";
import {
  annotUIshiftSub,
  convertTimeToPixel,
  create_UUID,
  handleHorizontalScroll,
  handlePrint,
  hideZoomControls,
  restThings,
  saveState,
  resetAnnotation,
  showStatText,
  toggleSpeakerImgSrc,
  changeSpeakerTrack,
  verticalScrollToView,
} from "../../../../utils/utils";
import { annotationListActions } from "../../../../redux/slice/AnnotationListSlice";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";
import { sfxModalSliceActions } from "../../../../redux/slice/SfxModalSlice";
import MultiselectReplaceModal from "../../../modals/ShowReplaceModal";

function TranscriptComponent() {
  const dispatch = useDispatch();
  let previousSpeakerImgSrc = "";
  const [replaceTranscriptVisible, setReplaceTranscriptVisible] =
    useState(false);
  const [showTranscriptLoader, setShowTranscriptLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [firstResultDisplayed, setFirstResultDisplayed] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [selectedWords, setSelectedWords] = useState([]);
  const [showModalFlag, setShowModalFlag] = useState(false);
  const [currentMatchIndex, setCurrentMatchIndex] = useState(0);
  const annotationList = store.getState().annotationList.annotationList;

  let multiSelectedWords = [];
  const wordMultiSelect = (annotation) => {
    let val = store.getState().multiSelectedWords.isMultiselect;

    if (val) {
      multiSelectedWords = [...multiSelectedWords, annotation];

      dispatch(
        multiSelectWordActions.updateMultiSelectWordList({
          wordsObject: multiSelectedWords,
        }),
      );
    } else {
      multiSelectedWords = [annotation];

      dispatch(
        multiSelectWordActions.updateMultiSelectWordList({
          wordsObject: multiSelectedWords,
        }),
      );
    }
  };

  const multiSelectOperation = () => {
    const multiselect = store.getState().multiSelectedWords.isMultiselect;
    if (!multiselect) {
      document.getElementById("multiselect-transcript").src =
        "/image/workspace/active-icons/annotations.png";
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: true }));
    } else {
      document.getElementById("multiselect-transcript").src =
        "/image/workspace/controls/annotations.png";
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: false }));

      // console.log(selectedWords);
      // remove box-shadow after deselect the multi select
      for (let i = 1; i < selectedWords.length; i++) {
        let annotId = selectedWords[i].id;
        // console.log(annotId);

        let annotdata = document.getElementById(`${annotId}_div`);
        // console.log(`${annotId}_div`);
        if (annotdata) {
          setTimeout(() => {
            annotdata.classList.remove("box-shadow");
          }, 0);
          // dispatch(annotationListActions.updateHighlightAnnotationList(annot));
        }
      }
    }
  };

  const handleAnnotationClick = (annot) => {
    console.log(annot, "here is annot");
    const wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
    const wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
    const regionaudio = wavesurferObj.regions.list;
    const regionSFX = wavesurferObjSFX.regions.list;
    for (const regionKey in regionaudio) {
      regionaudio[regionKey].remove();
    }
    for (const regionKey in regionSFX) {
      regionSFX[regionKey].remove();
    }
    wordMultiSelect(annot);
    wavesurferObj.addRegion({
      start: parseFloat(annot.start_time), // start time of the region
      end: parseFloat(annot.end_time), // end time of the region
      color: "hsla(265, 100%, 86%, 0.4)", // color of the region
    });
    // let annotdata = document.getElementById(annot.id + "_div");
    // // console.log(annot);
    // changeSpeakerTrack(annot, annotationList);
    // if (annotdata) {
    //   setTimeout(() => {
    //     annotdata.classList.add("clicked-annot");
    //   }, 0);
    // }
    // Commented out the above as it was causing issues in the side transcript section, check PlaylistArea.js file for same function
    const annotation = {
      id: `${annot.id}`,
      start: parseFloat(annot.start_time),
      end: parseFloat(annot.end_time),
      data: annot,
      drag: true,
      resize: true,
      // color: annot.color === "#00FFFFFF" ? "blueviolet" : "#00FFFFFF", // Toggle color on click
      color: "bluevoilet",
    };
    handleHorizontalScroll(convertTimeToPixel(annot.start_time));
    //     updatedSelectedWords.push(annot);
    // setClickedIndex(annot.id);

    wordMultiSelect(annotation);
  };

  let resultStat = useSelector((state) => state.projectData);

  const handleExport = () => {
    setShowModalFlag(true);
  };
  const closeModal = () => {
    setShowModalFlag(false); // Close the modal
  };
  const [isTooltipActive, setIsTooltipActive] = useState(false);
  const handleClick = () => {
    const transcriptWords = [];
    setIsTooltipActive(true);
    let dictionary = [];
    if (resultStat.resultStat.transcribed == false) {
      showStatText("transcription is not available for this project");
      return;
    }
    resultStat.resultStat.speakers_segments.forEach((speaker, index) => {
      transcriptWords.push(...speaker.annotations);
    });
    // Activate tooltips for the element with id 'transcript-text'

    // console.log("Transcript Words:", transcriptWords);
    if (transcriptWords.length > 0) {
      hideZoomControls();
      setShowTranscriptLoader(!showTranscriptLoader);
    } else {
      showStatText("Transcription is not available for this project");
    }
  };
  useBootstrapTooltips(isTooltipActive ? "transcript" : null);
  const handleExit = () => {
    setIsTooltipActive(false);
    setShowTranscriptLoader(false);
    setSearchTerm("");
    setSearchResults([]);
    setSearchPerformed(false);
    setFirstResultDisplayed(false);
    setSelectedWords([]);
    setClickedWordId(null);
    resetAnnotation();
  };

  const handleNextClick = () => {
    if (searchResults.length === 0) {
      return;
    }

    const currentword = searchResults[currentMatchIndex];

    let currannotdata = document.getElementById(`${currentword.id}_div`);

    if (currannotdata) {
      currannotdata.classList.remove("box-shadow");
    }
    const nextIndex = (currentMatchIndex + 1) % searchResults.length;
    setCurrentMatchIndex(nextIndex);
    const nextWord = searchResults[nextIndex];
    const transcriptionSectionAnnot = document.getElementById(
      `${nextWord.id}_span`,
    );

    const transcriptionModalElem = document.getElementById(`fullTranscript`);

    handleHorizontalScroll(convertTimeToPixel(nextWord.start_time));
    verticalScrollToView(transcriptionSectionAnnot, transcriptionModalElem);

    // console.log(prevWord);
    let annotdata = document.getElementById(`${nextWord.id}_div`);
    if (annotdata) {
      annotdata.classList.add("box-shadow");
    }
    // highlightWord(nextWord);
  };

  const handlePreviousClick = () => {
    if (searchResults.length === 0) {
      return;
    }
    const currentword = searchResults[currentMatchIndex];
    let currannotdata = document.getElementById(`${currentword.id}_div`);
    if (currannotdata) {
      currannotdata.classList.remove("box-shadow");
    }
    const prevIndex =
      (currentMatchIndex - 1 + searchResults.length) % searchResults.length;
    setCurrentMatchIndex(prevIndex);
    const prevWord = searchResults[prevIndex];

    const transcriptionSectionAnnot = document.getElementById(
      `${prevWord.id}_span`,
    );

    const transcriptionModalElem = document.getElementById(`fullTranscript`);
    verticalScrollToView(transcriptionSectionAnnot, transcriptionModalElem);

    handleHorizontalScroll(convertTimeToPixel(prevWord.start_time));
    let annotdata = document.getElementById(`${prevWord.id}_div`);
    if (annotdata) {
      annotdata.classList.add("box-shadow");
    }
    //find current index and remove box-shadow
    // highlightWord(prevWord);
  };

  const highlightWord = (word) => {
    const wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
    const wavesurferObjSFX = store.getState().wavesurferObjSFX.wavesurferObjSFX;
    const regionaudio = wavesurferObj.regions.list;
    const regionSFX = wavesurferObjSFX.regions.list;
    for (const regionKey in regionaudio) {
      regionaudio[regionKey].remove();
    }
    for (const regionKey in regionSFX) {
      regionSFX[regionKey].remove();
    }
    wavesurferObj.addRegion({
      start: parseFloat(word.start_time),
      end: parseFloat(word.end_time),
      color: "hsla(265, 100%, 86%, 0.4)",
    });
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchTranscript(value);

    // Clear selected words and multi-select when search term is empty
    if (!value.trim()) {
      setSelectedWords([]);
      dispatch(
        multiSelectWordActions.updateMultiSelectWordList({ wordsObject: [] }),
      );
      dispatch(multiSelectActions.updateMultiselect({ isMultiselect: false }));
    }
  };

  const handleSelectAll = () => {
    const updatedSelectedWords = [];
    // create the annot that needs to be pushed to multiselect operation
    searchResults.forEach((annot) => {
      const annotation = {
        id: `${annot.id}`,
        start_time: parseFloat(annot.start_time),
        end_time: parseFloat(annot.end_time),
        data: annot,
        drag: true,
        resize: true,
      };
      let annotdata = document.getElementById(`${annot.id}_div`);
      if (annotdata) {
        annotdata.classList.add("box-shadow");
      }
      updatedSelectedWords.push(annotation);

      wordMultiSelect(annotation); // Call wordMultiSelect for each word
    });
    setSelectedWords(updatedSelectedWords);
    dispatch(
      multiSelectWordActions.updateMultiSelectWordList({
        wordsObject: updatedSelectedWords,
      }),
    );
    multiSelectOperation();
  };

  const searchTranscript = (term) => {
    if (!term) {
      setSearchResults([]);
      setSearchPerformed(false);
      return;
    }

    const transcriptWords = [];

    resultStat.resultStat.speakers_segments.forEach((speaker) =>
      transcriptWords.push(...speaker.annotations),
    );

    const filteredResults = transcriptWords
      .filter((word, index) => {
        // Split the content into words
        const words = word.content.split(" ");

        // Check if any word in the content matches the search term as a whole word
        const match = words.some((w) => {
          const termUpperCase = term.toUpperCase();
          const wordUpperCase = w.toUpperCase();
          return wordUpperCase === termUpperCase;
        });

        return match;
      })
      .map((result, index) => {
        return { ...result, index };
      });

    // Filter the results again to ensure only exact matches are included
    const exactMatches = filteredResults.filter((word) =>
      word.content.toUpperCase().includes(term.toUpperCase()),
    );
    setSearchResults(exactMatches);
    setSearchPerformed(true);
    setCurrentMatchIndex(0);
    setFirstResultDisplayed(false);
    if (exactMatches.length > 0) {
      handleHorizontalScroll(convertTimeToPixel(exactMatches[0].start_time));

      const wavesurferObj = store.getState().wavesurferObj.wavsurferObj;
      const wavesurferObjSFX =
        store.getState().wavesurferObjSFX.wavesurferObjSFX;
      const regionaudio = wavesurferObj.regions.list;
      const regionSFX = wavesurferObjSFX.regions.list;
      for (const regionKey in regionaudio) {
        regionaudio[regionKey].remove();
      }
      for (const regionKey in regionSFX) {
        regionSFX[regionKey].remove();
      }
      const annotFirstId = exactMatches[0].id;
      let annotdataFirst = document.getElementById(`${annotFirstId}_div`);
      if (annotdataFirst) {
        annotdataFirst.classList.add("box-shadow");
      }
      for (let i = 0; i < exactMatches.length; i++) {
        let annotId = exactMatches[i].id;
        // console.log(annotId);

        let annotdata = document.getElementById(`${annotId}_div`);
        // console.log(`${annotId}_div`);
        if (annotdata) {
          setTimeout(() => {
            annotdata.classList.add("clicked-annot");
          }, 0);
          // dispatch(annotationListActions.updateHighlightAnnotationList(annot));
        }
        const transcriptionSectionAnnot = document.getElementById(
          `${annotFirstId}_span`,
        );

        const transcriptionModalElem =
          document.getElementById(`fullTranscript`);

        verticalScrollToView(transcriptionSectionAnnot, transcriptionModalElem);
        dispatch(
          annotationListActions.updateHighlightAnnotationList(exactMatches[i]),
        );
        // wavesurferObj.addRegion({
        //   start: parseFloat(exactMatches[i].start_time), // start time of the region
        //   end: parseFloat(exactMatches[i].end_time), // end time of the region
        //   color: "hsla(265, 100%, 86%, 0.4)", // color of the region
        // });
      }
    }
  };

  const [clickedWordId, setClickedWordId] = useState(null);
  const renderWord = (word) => {
    const isFirstExactMatch =
      searchResults.length > 0 && word.id === searchResults[0]?.id;
    const isSelected = selectedWords.some(
      (selected) => selected.id === word.id,
    );

    const isWordInSearch = word.content
      .toUpperCase()
      .includes(searchTerm.toUpperCase());
    const isWholeWordMatch =
      word.content.toUpperCase() === searchTerm.toUpperCase();

    //unhighlight
    if (!isWholeWordMatch) {
      // console.log(word);
      let annotdata = document.getElementById(`${word.id}_div`);
      if (annotdata) {
        annotdata.classList.remove("clicked-annot");
        annotdata.classList.remove("box-shadow");
      }
    }
    const isCurrentMatch = searchResults[currentMatchIndex]?.id === word.id;
    const isMultiselectActive =
      store.getState().multiSelectedWords.isMultiselect;
    const isSelectedAndMultiselectActive = isSelected && isMultiselectActive;

    const handleclickword = (clickedId) => {
      setClickedWordId(clickedId);
      handleAnnotationClick(word);
      dispatch(annotationListActions.updateHighlightAnnotationList(word));
    };

    const handleRightClick = (e, annot) => {
      e.preventDefault();
      store.dispatch(
        sfxModalSliceActions.updateSfxModal({
          showModal: true,
          clickedAnnot: annot.content,
        }),
      );
    };

    return (
      <span
        key={word.id}
        id={`${word.id}_span`}
        onClick={() => handleclickword(word.id)}
        onContextMenu={(e) => handleRightClick(e, word)} // Add this line
        className={clickedWordId === word.id ? "clicked-annot" : ""}
        style={{
          cursor: "pointer",
          color: word.is_silenced
            ? "#818181" // Silenced word color
            : isSelected
              ? "blueviolet"
              : isWholeWordMatch
                ? "blueviolet"
                : "white",
          border:
            isSelectedAndMultiselectActive ||
            isCurrentMatch ||
            (isFirstExactMatch && currentMatchIndex === null)
              ? "1px solid blueviolet"
              : "none",
          boxShadow:
            isSelectedAndMultiselectActive ||
            isCurrentMatch ||
            (isFirstExactMatch && currentMatchIndex === null)
              ? "0px 0px 5px blueviolet"
              : "none",
          padding:
            isSelectedAndMultiselectActive ||
            isCurrentMatch ||
            (isFirstExactMatch && currentMatchIndex === null)
              ? "2px"
              : "none",
        }}
      >
        {word.content}
      </span>
    );
  };
  const [showReplaceModal, setshowReplaceModal] = useState(false);
  const [showReplaceIcon, setShowReplaceIcon] = useState(false);

  function closeReplaceModal() {
    setshowReplaceModal(false);
  }
  function handleReplaceInputFunction() {
    if (store.getState().viewEditMode.mode == "view") {
      showStatText("operation not permitted in view mode");
      return;
    }
    if (document.getElementById("input-search-transcript").value.length > 0) {
      setShowReplaceIcon((prev) => !prev);
    } else {
      showStatText("please input text to replace");
      return;
    }
  }

  function handleReplaceTranscript() {
    let inputText = document
      .getElementById("input-search-transcript")
      .value.trim();
    let replaceText = document
      .getElementById("input-replace-transcript")
      .value.trim();
    if (replaceText.length == 0) {
      showStatText("Enter replace text...");
      return;
    }
    if (searchResults.length > 0) {
      setshowReplaceModal(true);
    } else {
      showStatText("No matched transcriptions...");
    }
  }
  function handleReplaceModalYes() {
    setSearchResults([]);
    closeReplaceModal();
    let inputText = document
      .getElementById("input-search-transcript")
      .value.trim();
    let replaceText = document
      .getElementById("input-replace-transcript")
      .value.trim();
    let resultStat = store.getState().projectData.resultStat;

    let newResultStat = JSON.parse(JSON.stringify(resultStat));
    let newResultStatForUndoRedo = JSON.parse(JSON.stringify(resultStat));
    let speakerSegmentTrackForSelectedWords = [];
    let items = [];
    searchResults.map((word, ind) => {
      let indexID = resultStat.speakers_segments.findIndex((segment) => {
        return segment.annotations.some((annot) => annot.id === word.id);
      });

      speakerSegmentTrackForSelectedWords.push(
        resultStat.speakers_segments[indexID].speaker_label,
      );
      items[ind] = {
        trackId: resultStat.speakers_segments[indexID].id,
        start: parseFloat(searchResults[ind].start_time),
        end: parseFloat(searchResults[ind].end_time),
        wordid: searchResults[ind].id,
        word: searchResults[ind].content,
      };
      //change the content in newResultstat
      newResultStat.speakers_segments[indexID].annotations.forEach(
        (annot, index) => {
          if (annot.id === word.id) {
            let newAnnot = { ...annot, content: replaceText };
            if (document.getElementById(annot.id + "_div")) {
              document.getElementById(annot.id + "_div").textContent =
                replaceText;
            }
            if (document.getElementById(annot.id + "_span")) {
              document.getElementById(annot.id + "_span").textContent =
                replaceText;
            }
            if (document.getElementById(annot.id + "_tspan")) {
              document.getElementById(annot.id + "_tspan").textContent =
                replaceText;
            }
            newResultStat.speakers_segments[indexID].annotations[index] =
              newAnnot;
          }
        },
      );
    });
    const uniqueTracks = [
      ...new Set(speakerSegmentTrackForSelectedWords.map(JSON.stringify)),
    ].map(JSON.parse);
    let operationId = create_UUID();
    let params = {
      jobname: resultStat.jobname,
      refid: operationId,
      parentRefid: resultStat.refid,
      worker: "multiselect-replace",
      inputs: {
        items,
        selectedCustomClasses: [...uniqueTracks],
        customClass: "speakers-0",
        searchWord: document
          .getElementById("input-search-transcript")
          .value.trim(),
        replaceWord: document
          .getElementById("input-replace-transcript")
          .value.trim(),
      },
    };
    newResultStatForUndoRedo.params = params;
    saveState(newResultStatForUndoRedo, "multiselect-replace");
    newResultStat.sfx_src = resultStat.sfx_src;
    newResultStat.refid = operationId;
    newResultStat.speakers_src = resultStat.speakers_src;
    newResultStat.fades = resultStat.fades ? resultStat.fades : [];
    setShowReplaceIcon(false);
    restThings(params, newResultStat);
  }
  const [hidespeaker, setHideSpeaker] = useState(false);
  const toggleId = "toggle-spk";
  useBootstrapTooltips(hidespeaker ? toggleId : toggleId);
  function toggleSpeaker() {
    setHideSpeaker((prev) => !prev);
    let toggleSpeakerIcon = document.getElementById("toggle-spk");
    if (toggleSpeakerIcon.src == "/img/transcript/c_transcript_speaker.png") {
      toggleSpeakerIcon.src = "/img/transcript/transcript_speakers.png";
    } else {
      toggleSpeakerIcon.src = "/img/transcript/c_transcript_speaker.png";
    }
    toggleSpeakerImgSrc();
  }

  return (
    <>
      <div>
        {!showTranscriptLoader && (
          <div
            className="transcript text-white"
            id="transcript-text"
            onClick={handleClick}
            style={{
              cursor: "pointer",
              position: "relative",
              textDecoration: "underline solid blueviolet",
              fontWeight: 600,
              fontSize: "1.1rem",
            }}
          >
            Transcript
          </div>
        )}
        {showTranscriptLoader && (
          <div
            className="transcript-loader disable"
            id="transcript"
            style={{
              transform: showTranscriptLoader
                ? "translateX(0)"
                : "translateX(100%)",
              transition: "transform 250ms ease-in-out",
              // backgroundImage:`url(${backgroundImage})`
            }}
          >
            <div className="row transcript-header">
              <div className="col-9 text-secondary trans-search">
                <input
                  className="transcriptSearch"
                  type="text"
                  id="input-search-transcript"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  placeholder=" Search..."
                  size="15"
                />
                {showReplaceIcon && (
                  <input
                    className="transcriptSearch"
                    type="text"
                    id="input-replace-transcript"
                    placeholder="Replace..."
                    size="15"
                  />
                )}
                {/* {replaceTranscriptVisible && (
                  <>
                    <input
                      className="transcriptSearch"
                      type="text"
                      id="input-replace-transcript"
                      placeholder="Replace..."
                      size="15"
                    />
                    <div
                      class="transcriptSearch-img transcriptSearchOptionSpacing-alt tt"
                      data-bs-placement="bottom"
                      title="match whole word"
                    >
                      <img
                        class="disable"
                        src="../img/workspace/controls/match-word.png"
                        id="transcript-replace-select"
                        style={{
                          height: "auto",
                          position: "absolute",
                          top: "3rem",
                        }}
                        alt=""
                      />
                    </div>
                  </>
                )} */}

                <div
                  className="searchOptions"
                  id="transcriptIcons"
                  style={{ position: "relative" }}
                >
                  {/* <div title="search previous"
                         class='transcriptSearch-icon transcriptSearch-prev transcriptSearchOptionSpacing'><i
                             onClick={searchTranscript} class="fa fa-long-arrow-up"
                             aria-hidden="true"></i></div>
                     <div title="search next"
                         class='transcriptSearch-icon transcriptSearch-next transcriptSearchOptionSpacing'><i
                             id="chev-down" onClick={searchTranscript} class="fa fa-long-arrow-down"
                             aria-hidden="true"></i></div>
                     */}
                  <div
                    className="transcriptSearch-img transcriptSearchOptionSpacing-alt tt transcriptDButtons"
                    data-bs-placement="bottom"
                  >
                    <img
                      className="disable"
                      src="/img/workspace/controls/replace.png"
                      id="btn-transcript-replace-div"
                      style={{
                        height: "auto",
                        width: "12.1479px",
                        cursor: "pointer",
                      }}
                      title="replace"
                      onClick={handleReplaceInputFunction}
                      alt=""
                    />
                    {showReplaceIcon && (
                      <img
                        className="disable"
                        src="/img/workspace/controls/rec-check.png"
                        id="multiselect-replace-transcript"
                        style={{ height: "auto" }}
                        onClick={handleReplaceTranscript}
                        alt=""
                      />
                    )}
                  </div>

                  <div
                    className="transcriptSearch-img transcriptSearchOptionSpacing-alt tt transcriptDButtons"
                    data-bs-placement="bottom"
                    title="previous"
                  >
                    <img
                      className="disable"
                      src="/img/transcript/transcriptUpArrow.png"
                      id="transcript-search-prev"
                      style={{ height: "auto", width: "12.1479px" }}
                      alt=""
                      onClick={handlePreviousClick}
                    />
                  </div>
                  <div
                    className="transcriptSearch-img transcriptSearchOptionSpacing-alt tt transcriptDButtonsNext"
                    data-bs-placement="bottom"
                    title="next"
                  >
                    <img
                      className="disable"
                      src="/img/transcript/transcriptDownArrow.png"
                      id="transcript-search-next"
                      style={{ height: "auto", width: "12.1479px" }}
                      alt=""
                      onClick={handleNextClick}
                    />
                  </div>
                  <div
                    className="transcriptSearch-img transcriptSearchOptionSpacing tt transcriptDButtonsLast"
                    data-bs-placement="bottom"
                    title="select all"
                    onClick={handleSelectAll}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      className="disable"
                      src="/img/workspace/controls/annotations.png"
                      id="multiselect-transcript"
                      style={{
                        height: "auto",
                        width: "12.1479px",
                        cursor: "pointor",
                      }}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-9 text-secondary trans-search"
                id="replace-transcript-div"
                style={{ visibility: "hidden" }}
              >
                {/* <input
                  className="transcriptSearch"
                  type="text"
                  id="input-replace-transcript"
                  placeholder="Replace..."
                  size="15"
                /> */}
                <div
                  className="searchOptions-replace w-25"
                  style={{ position: "relative" }}
                >
                  <div
                    className="transcriptSearch-img transcriptSearchOptionSpacing tt"
                    data-bs-placement="bottom"
                    title="replace all"
                    style={{ position: "absolute" }}
                  >
                    {/* <img
                      className="disable"
                      src="/img/workspace/controls/rec-check.png"
                      id="multiselect-replace-transcript"
                      style={{ height: "auto" }}
                      onClick={handleReplaceTranscript}
                      alt=""
                    /> */}
                  </div>
                  {/* <div
                    className="transcriptSearch-img transcriptSearchOptionSpacing-alt tt"
                    data-bs-placement="bottom"
                    title="match whole word"
                    style={{ display: "none" }}
                  >
                    <img
                      className="disable"
                      src="/img/workspace/controls/match-word.png"
                      id="transcript-replace-select"
                      style={{ height: "auto" }}
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
              <div
                className="close-transcript transc"
                style={{
                  cursor: "pointer",
                  textAlign: "end",
                  position: "absolute",
                  padding: "0%",
                }}
              >
                <img
                  title="close transcript"
                  style={{ width: "5%" }}
                  id="close-transcript"
                  onClick={handleExit}
                  src="/img/exit-workspace.svg"
                  alt=""
                />
              </div>
              <div
                id="transcriptDrivenCustomClassesCont"
                className="col-5 d-none align-items-center"
                style={{ paddingLeft: "4%" }}
              >
                <div
                  className="add-spk-sfx transcriptSearch-img transcriptDrivenSearchOptionSpacing-alt"
                  id="add-spk-sfx-transcript"
                  style={{ zIndex: 11, display: "none" }}
                >
                  <img
                    src="./img/add_tracks.svg"
                    className="add-spk-sfx-tracks tt"
                    data-bs-placement="bottom"
                    title="add track"
                    id="add-spk-sfx-tracks-transcript"
                    alt=""
                  />
                </div>
                <div
                  id="transcriptSFXSliderBox"
                  style={{ width: "25%", color: "#8A2BE2 !important" }}
                ></div>
              </div>
              <div
                id="transcriptDrivenButtons"
                className="d-none col-3 justify-content-end align-items-center"
                style={{ cursor: "pointer", paddingRight: "2%" }}
              >
                <div
                  id="menu-vol-ctrl-spk"
                  className="d-flex align-items-center"
                >
                  <img
                    src="/img/volume-ctrl.svg"
                    id="spk-volume-alt-icon"
                    className="volume-alt-icon-transcript transcriptSearchOptionSpacing"
                    alt=""
                  />

                  <div
                    id="spk-volume-val"
                    className="speakers-vol text-white transcriptSearchOptionSpacing"
                    style={{ fontSize: "1.35vw", marginTop: "0.6vh" }}
                  >
                    5
                  </div>
                </div>
                <div
                  className="btn-trans-speakers trans-speakers transcriptSearch-img transcriptDrivenSearchOptionSpacing tt"
                  id="transcript-toggle-t-spk"
                  style={{ textAlign: "center" }}
                  data-bs-placement="bottom"
                  title="hide speakers"
                >
                  <img
                    className="show-spk"
                    id="toggle-t-spk"
                    src="/img/transcript/c_transcript_speaker.png"
                    style={{ cursor: "pointer" }}
                    alt=""
                  />
                </div>
                <div
                  className="btn-trans-download trans-download transcriptSearch-img transcriptDrivenSearchOptionSpacing tt"
                  style={{ textAlign: "center" }}
                  data-bs-placement="bottom"
                  title="export transcript"
                >
                  <img
                    className=""
                    src="/img/transcript/export_transcript.png"
                    id="exportTranscriptIcon"
                    style={{ cursor: "pointer" }}
                    alt=""
                  />
                </div>
                <div
                  className="btn-trans-export trans-export transcriptSearch-img transcriptDrivenSearchOptionSpacing tt"
                  style={{ textAlign: "center" }}
                  data-bs-placement="bottom"
                  title="print transcript"
                >
                  <img
                    className=""
                    src="/img/transcript/print_transcript.png"
                    style={{ cursor: "pointer" }}
                    id="printTranscriptIcon"
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div
              className="transcript-main m-auto text-white text-justify font-weight-bold"
              id="fullTranscript"
            >
              <div id="transcription-wrapperpara">
                {resultStat.resultStat.speakers_segments.map(
                  (speaker, speakerIndex) => {
                    const sortedAnnotations = [...speaker.annotations].sort(
                      (a, b) => {
                        return a.start_time - b.start_time;
                      },
                    );
                    let speakerlabel = speaker.speaker_label.split("-");
                    let speakerHeader = speakerlabel[0];
                    let speakerNumber = JSON.parse(speakerlabel[1]) + 1;
                    let speakerImgSrc = speakerHeader + "-" + speakerNumber;
                    let shouldDisplayHeader = true; // Flag to control display of header
                    // we will check whether the previous speaker segment is same as the current segment or not
                    if (speakerIndex === 0) {
                      previousSpeakerImgSrc = speaker.speaker_label;
                    } else {
                      if (speaker.annotations.length > 0) {
                        if (speaker.speaker_label == previousSpeakerImgSrc) {
                          shouldDisplayHeader = false;
                        } else {
                          previousSpeakerImgSrc = speaker.speaker_label;
                        }
                      }
                    }

                    let imgSrc =
                      "https://ui-avatars.com/api/?name=" +
                      speakerImgSrc +
                      "&background=BB417C&rounded=true&size=31&color=fff";
                    const speakerImageHTML = `<img id='${speaker.id}-spk'  src='${imgSrc}' class='spk-slid'>`;

                    return (
                      <>
                        {shouldDisplayHeader
                          ? sortedAnnotations.length > 0 && (
                              <div key={speakerIndex}>
                                {resultStat.resultStat.speakers_segments
                                  .length > 1 && (
                                  <>
                                    <br />
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: speakerImageHTML,
                                      }}
                                    />
                                    <br />
                                  </>
                                )}

                                {sortedAnnotations.map((annot, index) => (
                                  <p
                                    key={annot.id}
                                    style={{
                                      margin: "0.4rem",
                                      fontFamily: "avenir",
                                      fontWeight: "700",
                                      padding: "0.2rem",
                                      fontSize: "13px",
                                      cursor: "pointer",
                                      display: "inline-block",
                                    }}
                                  >
                                    {renderWord(annot, index)}
                                  </p>
                                ))}
                              </div>
                            )
                          : sortedAnnotations.map((annot, index) => (
                              <p
                                key={annot.id}
                                style={{
                                  margin: "0.4rem",
                                  fontFamily: "avenir",
                                  fontWeight: "700",
                                  padding: "0.2rem",
                                  fontSize: "13px",
                                  cursor: "pointer",
                                  display: "inline-block",
                                }}
                              >
                                {renderWord(annot, index)}
                              </p>
                            ))}
                      </>
                    );
                  },
                )}
              </div>
            </div>

            <div id="exportTranscript" className="export-transcript"></div>

            <div className="transcript-menu">
              <div
                className="btn-trans-speakers trans-speakers icon-spacing tt"
                id="transcript-toggle-spk"
                style={{ textAlign: "center" }}
                data-bs-placement="bottom"
                title={hidespeaker ? "hide speakers" : "show speakers"}
              >
                <img
                  className="show-spk"
                  id="toggle-spk"
                  src={
                    hidespeaker
                      ? "/img/transcript/transcript_speakers.png"
                      : "/img/transcript/c_transcript_speaker.png"
                  }
                  style={{ cursor: "pointer" }}
                  alt=""
                  onClick={toggleSpeaker}
                />
              </div>
              <div
                className="btn-trans-download trans-download icon-spacing tt"
                style={{ textAlign: "center" }}
                data-bs-placement="bottom"
                title="export transcript"
                onClick={handleExport}
              >
                <img
                  src="/img/transcript/export_transcript.png"
                  id="exportTranscriptIcon"
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </div>
              <div
                className="btn-trans-export trans-export icon-spacing tt"
                style={{ textAlign: "center" }}
                data-bs-placement="bottom"
                title="print transcript"
                onClick={handlePrint}
              >
                <img
                  src="/img/transcript/print_transcript.png"
                  style={{ cursor: "pointer" }}
                  id="printTranscriptIcon"
                  alt=""
                />
              </div>
            </div>
          </div>
        )}

        {showModalFlag && <ExportTranscript closeModal={closeModal} />}
        {showReplaceModal && (
          <MultiselectReplaceModal
            replaceWord={document
              .getElementById("input-replace-transcript")
              .value.trim()}
            searchWord={document
              .getElementById("input-search-transcript")
              .value.trim()}
            onClose={closeReplaceModal}
            onReplaceYes={handleReplaceModalYes}
          ></MultiselectReplaceModal>
        )}
      </div>
    </>
  );
}

export default TranscriptComponent;
