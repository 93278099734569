import { createSlice } from "@reduxjs/toolkit";
const initialUser = {
  userName: sessionStorage.getItem("user") || "",
  revoked: false,
  collaboratorInKooliospace: false,
  //   email: "",
};
const SignInUserSlice = createSlice({
  name: "user",
  initialState: initialUser,
  reducers: {
    setUser(state, action) {
      //   console.log(action.payload);
      sessionStorage.setItem("user", action.payload);
      state.userName = action.payload;
      //   console.log(state.userName);
    },
    setCurrentProjCollabAccessRevoked(state, action) {
      state.revoked = action.payload.revokeKey;
    },
    setcollaboratorInKooliospace(state, action) {
      state.collaboratorInKooliospace =
        action.payload.collaboratorInKooliospaceKey;
    },
  },
});

export default SignInUserSlice.reducer;
export const setSignInUserAction = SignInUserSlice.actions;
