import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import $ from "jquery";
import "../../css/dark/exportTranscriptionsModal.css";
import useBootstrapTooltips from "../../hooks/useBootstrapTooltips";
import { handlePrint } from "../../utils/utils";

const Export = ({ closeModal }) => {
  useEffect(() => {
    document.getElementById("exportTranscriptFilename").value =
      document.getElementById("projectname").innerText;
    $("#exportTranscriptFilename").focus();
  }, []);

  const handleDownload = (fileNameExport) => {
    let exportFileName;
    if (!fileNameExport) {
      exportFileName = "Transcript";
    } else {
      exportFileName = fileNameExport;
    }
    handlePrint(exportFileName, true);
  };
  return createPortal(
    <>
      <div className="recordingName-wrapper-transcription" onClick={closeModal}>
        <div
          className="half-modals-content"
          id="half-modals-content"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="half-modals-header">
            Export transcript
            <img
              title="close"
              className="img-fluid"
              onClick={closeModal}
              id="closeWorkspaceModalIcon"
              style={{ cursor: "pointer" }}
              src="/img/exit-workspace.svg"
              alt=""
            />
          </div>
          <div className="half-modals-sub-header">
            Please select the type of document you want to download from the
            dropdown
          </div>
          <div className="export-transcript-filename-cont">
            <div style={{ marginRight: "0.5vw" }}>Transcript filename:</div>
            <div>
              <input
                id="exportTranscriptFilename"
                class="export-transcript-filename-input"
                type="text"
                autocomplete="ofF"
                placeholder="Enter transcript filename.."
                style={{ color: "#E2522B" }}
              />
            </div>
          </div>
          <div
            className="export-transcript-select half-modals-action"
            id="export-transcript-select"
            style={{ position: "relative" }}
          >
            <ul
              className="dropdown dropdown-format-options export-transcript-selected"
              id="export-trans-parent"
            >
              <li className="dd-selected" value="pdf" id="export-trans">
                PDF (PDF Document)
                <i
                  className="fas fa-caret-down"
                  style={{ position: "absolute", top: "22%", right: "9%" }}
                ></i>
              </li>
            </ul>
          </div>
          <div className="half-modals-action download-transcript">
            <div
              className="half-modals-actions text-center"
              style={{ width: "15%" }}
            >
              <button
                type="button"
                className="half-modals-action-button handlebutton"
                onClick={() => {
                  handleDownload(
                    document.getElementById("exportTranscriptFilename").value,
                  );
                }}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.body,
  );
};

export default Export;
