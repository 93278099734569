import React from "react";
import "../../../../css/dark/navbar.css";
import { CircleHelp } from "lucide-react";

export default function HelpButton({ handleHelpButtonClick }) {
  return (
    <div onClick={handleHelpButtonClick}>
      <CircleHelp className="help-button-navbar" />
    </div>
  );
}
