import React, { useState, useEffect } from "react";
import ToggleButton from "./ToggleButton";
import HelpButton from "./helpbutton";
import RightPanel from "./RightPanel";
import "../../../../css/dark/navbar.css";

import { Dropdown, Navbar } from "react-bootstrap";
import i18n from "i18next";
import { useNavigate } from "react-router-dom";
import ProjectTitle from "./Title";
import MoreOptions from "../modals/MorePage";
import { Koolioai } from "../../../../utils/cognitoAuth";
import ProfileModal from "../../../modals/profileModal";
import { fetchuserName } from "../../../../utils/utils";
import useBootstrapTooltips from "../../../../hooks/useBootstrapTooltips";
import TourGuideModal from "../../../modals/TourGuideModal";
import WorkspaceTour from "../../../tours/WorkspaceTour";
import { CircleUserRound, Menu } from "lucide-react";
const NavbarPage = (props) => {
  const [showProfile, setShowProfile] = useState("");
  const [showProfileModal, setShowProfileModal] = useState(false);
  const lngs = [
    { code: "en", nativeName: "English" },
    { code: "fr", nativeName: "Francais" },
  ];
  const navigate = useNavigate();
  const [moreOptionCLickedFromWorkspace, setMoreOptionClickedFromWorkspace] =
    useState(false);

  const handlemoreoption = () => {
    //   navigate("/More" ,{ state: { blurBackground: true } })
    setMoreOptionClickedFromWorkspace(true);
  };
  function closeMoreOption() {
    setMoreOptionClickedFromWorkspace(false);
  }

  const userProfileModal = (event, flag) => {
    event.preventDefault();
    setShowProfileModal(true); // Show the modal
  };

  const closeModal = () => {
    setShowProfileModal(false); // Close the modal
  };

  useEffect(() => {
    const profileImageUrl = fetchuserName();
    setShowProfile(profileImageUrl);
  }, []);

  const [showTourGuideModal, setShowTourGuideModal] = useState(false);

  return (
    <>
      <nav>
        <nav className="custom-navbar">
          <div className="navbar-container">
            {props.env === "workspace" ? (
              <>
                <ProjectTitle title={props.fileName} />
                <RightPanel env={props.env} onClose={props.onClose} />
              </>
            ) : (
              // Display all components for other environments
              <>
                <div className="d-flex  justifyContent-evenly">
                  <div
                    className="more-optionsicon"
                    onClick={handlemoreoption}
                    href="#"
                  >
                    <Menu id="more-options-icon" title="More Options" />
                  </div>
                  <div
                    className="header-koolio"
                    href="#"
                    onClick={() => navigate("/kooliospace")}
                  >
                    <img
                      className="img-fluid koolio-header-img"
                      src="/image/header/koolio-header-icon.svg"
                      alt="Bootstrap"
                    />
                  </div>
                  {window.location.pathname !== "/record_space" && (
                    <ToggleButton isChecked={false} />
                  )}
                  <HelpButton
                    handleHelpButtonClick={() => setShowTourGuideModal(true)}
                  />
                  <TourGuideModal
                    showTourGuideModal={showTourGuideModal}
                    closeTourGuideModal={() => setShowTourGuideModal(false)}
                    env={props.env}
                    onClose={props.onClose}
                    openMoreOptions={() =>
                      setMoreOptionClickedFromWorkspace(true)
                    }
                    openTranscriptDrivenMode={props.setIsCheckedTrue}
                    openWorkspaceTour={props.setIsCheckedFalse}
                  />
                </div>
                <ProjectTitle title={props.fileName} />
                <RightPanel env={props.env} onClose={props.onClose} />
                <div className="col-6 text-right">
                  <div className="avatars" id="shareholder-profile-cont"></div>
                  <span onClick={(event) => userProfileModal(event, false)}>
                    <CircleUserRound
                      className="user-profile-navbar"
                      id="user-profile-home"
                      title="User Profile"
                    />
                  </span>
                </div>
              </>
            )}
          </div>
        </nav>
      </nav>
      {moreOptionCLickedFromWorkspace && (
        <MoreOptions
          onClose={closeMoreOption}
          moreOptionCLickedFromWorkspace={moreOptionCLickedFromWorkspace}
        ></MoreOptions>
      )}
      {showProfileModal && <ProfileModal closeModal={closeModal} />}
    </>
  );
};

export default NavbarPage;
