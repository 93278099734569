import React from "react";
import "./../../css/dark/SideDrawer.css";
import tippy from "tippy.js";

const SideDrawer = ({
  currentSelectedOpt,
  searchText,
  handleSearch,
  creatCustomClassOperation,
  handleActiveSpeakerChange,
  handleSpeakerNameChange,
  handleSFXTrackHighlight,
  handleSfxMusicNameChange,
  resultStatCopy,
  resultStat,
}) => {
  // Initialize tooltip here for the "Add New Speaker" div
  const handleTooltip = (e) => {
    tippy(e.target, {
      content: "Add New Speaker",
      trigger: "mouseenter",
      hideOnClick: true,
      animation: "fade",
      delay: [500, 100], // Delay for showing and hiding
      theme: "custom", // Use a custom theme
      onShow(instance) {
        const tooltipBox = instance.popper.firstElementChild;
        tooltipBox.style.backgroundColor = "black"; // Set background to black
        tooltipBox.style.color = "white"; // Set text color to white
        tooltipBox.style.borderRadius = "4px"; // Optional: Rounded corners
      },
    });
  };
  return (
    <div
      id="side-drawer"
      style={{
        position: "absolute",
        fontWeight: 900,
        top: 0,
      }}
    >
      {/* Top Flex Container */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5%",
        }}
      >
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={handleSearch}
          className="side-drawer-search"
        />
      </div>

      {/* Conditional Drawers */}
      <div>
        {currentSelectedOpt === "speakers" && (
          <div id="drawer">
            {resultStatCopy?.speakers ? (
              Object.keys(resultStatCopy.speakers)
                .filter((key) =>
                  resultStatCopy.speakers[key]?.name
                    ?.toLowerCase()
                    .includes(searchText.toLowerCase()),
                )
                .map((key, index) => (
                  <input
                    id="speakerName"
                    type="text"
                    key={index}
                    className="contentEditableInputSpeaker"
                    value={resultStatCopy.speakers[key]?.name}
                    onClick={(e) =>
                      handleActiveSpeakerChange(e, resultStatCopy, key)
                    }
                    onContextMenu={(e) => {
                      e.preventDefault();
                      e.target.removeAttribute("readonly");
                      e.target.focus();
                    }}
                    onBlur={(e) => {
                      e.target.setAttribute("readonly", true);
                    }}
                    readOnly
                    onChange={(e) =>
                      handleSpeakerNameChange(e, resultStatCopy, key)
                    }
                    onKeyDown={(e) => {
                      if (e.key === "Enter") e.target.blur();
                    }}
                  />
                ))
            ) : (
              <div>No Speaker tracks found</div>
            )}
          </div>
        )}

        {currentSelectedOpt === "sfx/music" && (
          <div id="drawer">
            {resultStatCopy?.sfx ? (
              Object.keys(resultStatCopy.sfx)
                .filter(
                  (key) =>
                    resultStatCopy.sfx[key]?.name
                      ?.toLowerCase()
                      .includes(searchText.toLowerCase()) ||
                    resultStatCopy.sfx[key]?.name
                      .split(" ")
                      .map((word, index) =>
                        index < 2 ? word[0].toUpperCase() : word,
                      )
                      .join("")
                      ?.toLowerCase()
                      .includes(searchText.toLowerCase()),
                )
                .map((key, index) => (
                  <div key={index}>
                    <span id="sfx-short">{`${resultStatCopy.sfx[key]?.name
                      .split(" ")
                      .map((word, index) =>
                        index < 2 ? word[0].toUpperCase() : word,
                      )
                      .join("")} : `}</span>
                    <input
                      type="text"
                      className="contentEditableInputSfx"
                      value={resultStatCopy.sfx[key]?.name}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        e.target.removeAttribute("readonly");
                        e.target.focus();
                      }}
                      onClick={(e) =>
                        handleSFXTrackHighlight(key, resultStatCopy)
                      }
                      onBlur={(e) => {
                        e.target.setAttribute("readonly", true);
                      }}
                      readOnly
                      onChange={(e) =>
                        handleSfxMusicNameChange(e, resultStatCopy, key)
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") e.target.blur();
                      }}
                    />
                  </div>
                ))
            ) : (
              <div>No SFX/Music tracks found</div>
            )}
          </div>
        )}
      </div>

      {currentSelectedOpt === "speakers" && (
        <div
          style={{ padding: "0 5%" }}
          onClick={() => creatCustomClassOperation("speakers")}
          className="add-speaker-container"
          onMouseEnter={handleTooltip}
        >
          +
        </div>
      )}
    </div>
  );
};

export default SideDrawer;
