import React from "react";
import Joyride from "react-joyride";
import store from "../../redux/Store";
import "../../css/dark/ProductTours.css";
import { moreOptionsTourActions } from "../../redux/slice/MoreOptionsTourSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Koolioai, _config } from "../../utils/cognitoAuth";
import axios from "axios";
import topbar from "topbar";

// Custom Tooltip Component with Skip Button and Step Title
const CustomTooltip = ({
  index,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
  isLastStep,
  totalSteps,
  goToNextStep,
  goToPreviousStep,
}) => {
  return (
    <div {...tooltipProps} className="tooltip-container">
      {step.title && <div className="tooltip-title">{step.title}</div>}

      <div className="tooltip-content">{step.content}</div>
      <div className="tooltip-footer">
        <button {...skipProps} className="tooltip-skip-button">
          Skip
        </button>
        {index > 0 && (
          <button
            {...backProps}
            className="tooltip-back-button"
            onClick={goToPreviousStep}
          >
            Back
          </button>
        )}
        <button
          {...primaryProps}
          className="tooltip-next-button"
          onClick={goToNextStep}
        >
          {isLastStep ? "Finish" : "Next"}
        </button>
      </div>
    </div>
  );
};

// Tour Component
const MoreOptionsTour = (props) => {
  const dispatch = useDispatch();
  const runMoreOptionsTour = useSelector(
    (state) => state.moreOptionsTour.runMoreOptionsTour,
  );
  const [stepIndex, setStepIndex] = useState(0);
  const [autoAdvanceTimeout, setAutoAdvanceTimeout] = useState(null);
  const steps = [
    {
      target: "#history-cont",
      title: "History Section",
      content:
        "This premium feature empowers you to access a comprehensive history of all past operations performed on a specific file. Moreover, it grants you the capability to restore your file to any given point from that history.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: "#share-cont",
      title: "Share",
      content:
        "This premium feature enables you to share your project with other users, fostering collaboration and allowing them to actively contribute to your project.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: "#export-cont",
      title: "Export",
      content:
        "Within this feature, you have the ability to export the edited file using a variety of versatile options.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: "#output-cont",
      title: "Export Formats",
      content:
        "Choose the desired export formats for your audio, from wav, mp3, and m4a.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#publish-cont",
      title: "Publish",
      content:
        "Click this button to effortlessly publish your audio to a range of platforms such as Buzzsprout, Spreaker, Castos, Transistor, and more.",
      placement: "top",
      disableBeacon: true,
    },
    {
      target: "#genAi-cont",
      title: "Gen AI",
      content:
        "This is an another amazing premium feature that allows you to create AI generated Music, SFX, Notes and use them in your project.",
      placement: "right",
      disableBeacon: true,
    },
    {
      target: "#delete-cont",
      title: "Delete",
      content:
        "To remove your project including the audio, click here to delete it.",
      placement: "right",
      disableBeacon: true,
    },
  ];

  useEffect(() => {
    const fetchTourGuideData = async () => {
      try {
        const Tour_name = "more_options_tour"; // Set the current tour name
        const localStorageKey = `${Koolioai.username}${Tour_name}`; // Generate the key

        if (localStorage.getItem(localStorageKey) === "true") {
          return;
        }

        // Fetch tokens
        const tokens = await Promise.all([
          Koolioai.authToken(),
          Koolioai.accessToken(),
        ]);

        // Make the GET request
        const response = await axios.get(
          _config.api + "/demo-tour-guide-shown",
          {
            headers: {
              Authorization: `${tokens[0]}`,
              AccessToken: `${tokens[1]}`,
            },
          },
        );

        // Check the `transcript_tour` status
        if (response.data.demo_tour_guide.more_options_tour === true) {
          localStorage.setItem(localStorageKey, "true");
        } else {
          if (props.moreOptionsActive === true) {
            // Start the tour and mark it as completed
            dispatch(moreOptionsTourActions.startMoreOptionsTour());
            // Make the POST request to update the status
            axios
              .post(
                _config.api + "/demo-tour-guide-shown",
                { more_options_tour: true },
                {
                  headers: {
                    Authorization: `${tokens[0]}`,
                    AccessToken: `${tokens[1]}`,
                  },
                },
              )
              .then(() => {
                localStorage.setItem(localStorageKey, "true"); // Mark as completed in localStorage
              })
              .catch((error) =>
                console.error("Error updating tour guide data:", error),
              );
          }
        }
      } catch (error) {
        console.error("Error fetching or updating tour guide data:", error);
      }
    };

    fetchTourGuideData();
  }, [dispatch, props.moreOptions]);

  useEffect(() => {
    // Clear any ongoing timeouts or intervals
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);

    // Hide the top bar on the last step
    if (stepIndex === steps.length - 1) {
      topbar.hide();
      return; // Exit early to avoid setting up progress on the last step
    }

    if (runMoreOptionsTour) {
      topbar.show(); // Show the topbar
      topbar.progress("0"); // Reset progress

      let progress = 0;
      const interval = 32; // Milliseconds per increment
      const increment = 0.01; // Progress increment per interval
      let progressInterval;

      const startProgressBar = () => {
        progressInterval = setInterval(() => {
          progress += increment;
          if (progress >= 1) {
            clearInterval(progressInterval);
            topbar.progress("1"); // Ensure it ends at full
          } else {
            topbar.progress(progress.toString());
          }
        }, interval);
      };

      // Start the progress bar
      startProgressBar();

      // Advance to the next step after 4 seconds
      const timeout = setTimeout(() => {
        clearInterval(progressInterval); // Stop progress bar updates
        setStepIndex((prevIndex) => prevIndex + 1);
      }, 4000);

      setAutoAdvanceTimeout(timeout);

      return () => {
        clearTimeout(timeout);
        clearInterval(progressInterval);
      };
    }
  }, [runMoreOptionsTour, stepIndex]);

  // Reset the tour state after it has been run
  const handleTourEnd = () => {
    topbar.hide(); // Dispose the topbar
    dispatch(moreOptionsTourActions.stopMoreOptionsTour());
    setStepIndex(0);
    if (autoAdvanceTimeout) clearTimeout(autoAdvanceTimeout);
  };

  const goToNextStep = () => {
    if (stepIndex < steps.length - 1) {
      setStepIndex((prevIndex) => prevIndex + 1);
    }
    if (stepIndex === steps.length - 1) {
      handleTourEnd(); // Handle last step manually
    }
  };

  const goToPreviousStep = () => {
    if (stepIndex > 0) {
      setStepIndex((prevIndex) => prevIndex - 1);
    }
    if (autoAdvanceTimeout) {
      clearTimeout(autoAdvanceTimeout);
      topbar.progress("0");
    }
  };

  return (
    <Joyride
      steps={steps}
      continuous
      disableOverlayClose
      disableScrolling
      hideCloseButton
      showSkipButton={true} // Enable skip button
      showProgress={true} // Enable step progress
      scrollToFirstStep={true}
      run={runMoreOptionsTour}
      stepIndex={stepIndex}
      callback={(data) => {
        if (data.status === "finished" || data.status === "skipped") {
          handleTourEnd(); // Stop tour when it's done
        }
      }}
      styles={{
        options: {
          zIndex: 10000,
          overlayColor: "rgba(24,24,24,1)",
          arrowColor: "#C4C4C4",
        },
      }}
      tooltipComponent={(props) => (
        <CustomTooltip
          {...props}
          totalSteps={steps.length}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
    />
  );
};

export default MoreOptionsTour;
