import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../../../css/dark/KoolioNavbar.css";
import "../../../css/dark/KoolioNavbarV2.css";
import { showNotifications } from "./kooliospaceUtils";
import ProfileModal from "../../../components/modals/profileModal";
import { Bell, CircleUserRound, Search } from "lucide-react";

function KoolioNavbar({ fileList, prepareKooliospaceUI }) {
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [notificationClick, setNotificationClick] = useState(true);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const notificationContainer = document.querySelector(".notification");
      const notificationIcon = document.getElementById("notification_icon");
      if (
        notificationContainer &&
        !notificationContainer.contains(event.target)
      ) {
        if (notificationContainer.style.display === "block") {
          notificationContainer.style.display = "none";
          notificationIcon.classList.replace(
            "notification_icon_active",
            "notification_icon_inactive",
          );
        } else {
          setNotificationClick(true);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNotifications = (event) => {
    if (event) event.preventDefault();

    setNotificationClick((prevState) => !prevState);
    if (notificationClick) {
      showNotifications();
    }
  };

  const userProfileModal = (event, flag) => {
    if (event) event.preventDefault();
    setShowProfileModal(true); // Show the modal
  };

  const closeModal = () => {
    setShowProfileModal(false); // Close the modal
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    const list = fileList;
    const filteredUploadFileList = list.filter((uploadedFile) =>
      uploadedFile.projectTitle
        .toLowerCase()
        .includes(e.target.value.toLowerCase()),
    );
    if (e.target.value.length > 1) {
      prepareKooliospaceUI(filteredUploadFileList);
    } else {
      prepareKooliospaceUI(fileList);
    }
    if (e) e.preventDefault();
  };

  // Handle click outside the search bar
  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      document.getElementById("search_icon").classList.remove("d-none");
      document.getElementById("searchBoxInKoolioSpace").classList.add("d-none");
    }
  };

  // Add event listener for clicks outside the search bar
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleSearchIconClick() {
    document.getElementById("search_icon").classList.add("d-none");
    document
      .getElementById("searchBoxInKoolioSpace")
      .classList.remove("d-none");
  }
  return (
    <>
      <header className="site-header-home" id="site-header-home">
        <div className="koolio-navbar">
          <a
            style={{
              all: "unset",
              cursor: "pointer",
            }}
            href="https://www.koolio.ai"
          >
            <img
              src="/image/landing-page/koolio.ai.png"
              className="navbar-logo"
              alt="koolio.ai"
            />
          </a>

          <div className="navbar-right">
            <div className="Container_search_in_koolio">
              <input
                className="search_in_koolio d-none"
                id="searchBoxInKoolioSpace"
                onFocus={() => setNotificationClick((prevState) => !prevState)}
                type="text"
                placeholder="Search..."
                ref={searchRef}
                value={searchTerm}
                onChange={(e) => handleChange(e)}
              />
              <Search
                className="search_icon"
                id="search_icon"
                title="Search"
                onClick={handleSearchIconClick}
              />
            </div>

            <div className="Container_notification_in_koolio">
              <Bell
                className="notification_icon notification_icon_inactive"
                id="notification_icon"
                title="Notification"
                onClick={handleNotifications}
              />
              <h5 className="pending_notification" id="pending_notification">
                9+
              </h5>
            </div>

            <div className="notification" id="notification-div">
              <ul className="notification-list" id="notification-list">
                {/* <li className="notification-content">
                Saha has invited you to collaborate on a koolio project
              </li> */}
              </ul>
            </div>

            <CircleUserRound
              className="user-profile"
              id="user-profile-home"
              title="User Profile"
              onClick={(event) => userProfileModal(event, false)}
            />
          </div>
        </div>
      </header>
      {showProfileModal && <ProfileModal closeModal={closeModal} />}
    </>
  );
}

export default KoolioNavbar;
